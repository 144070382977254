var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("sidebar", { attrs: { menuItems: _vm.menuItems } }),
      _vm._v(" "),
      _c("main", [
        _c(
          "div",
          { staticClass: "container-fluid" },
          [
            _c(
              "transition",
              { attrs: { name: "zoom", mode: "out-in" } },
              [_c("router-view", { key: _vm.$route.path })],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }