<template>
  <div>
    <sidebar :menuItems="menuItems" />
    <main>
      <div class="container-fluid">
        <transition name="zoom" mode="out-in">
          <router-view :key="$route.path" />
        </transition>
      </div>
    </main>
  </div>
</template>

<script>
import Sidebar from '@/containers/Sidebar'
import { menuItems } from '@/constants/menu'
export default {
  components: {
    sidebar: Sidebar
  },
  data() {
    return {
      selectedMenu: '',
      menuItems
    }
  }
}
</script>

<style lang="scss" scoped>
.dashboard-main {
  margin-left: 50px;
}
.zoom-enter-active,
.zoom-leave-active {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-name: zoom;
}

.zoom-leave-active {
  animation-direction: reverse;
}

@keyframes zoom {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  100% {
    opacity: 1;
  }
}
</style>
